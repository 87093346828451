import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Paths } from "shared/config";
import {
  Box,
  Button,
  Container,
  IconInfo,
  IconOpenIn,
  Link,
  Stack,
  Typography,
} from "shared/ui";

export const PageWelcome = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleLoginButtonClick = () => {
    navigate(Paths.Login);
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        py: 2,
      }}
    >
      <Box>
        <Box
          component="img"
          display="block"
          maxWidth={245}
          mb={1}
          mx="auto"
          src="https://static.ahapay.my/logos/full.svg"
          width="100%"
        />
        <Typography
          align="center"
          color="neutral.50"
          mb={5}
          variant="bigTextRegular"
        >
          {t("welcome.title")}
        </Typography>
        <Button
          fullWidth={true}
          onClick={handleLoginButtonClick}
          color="primary"
          sx={{
            mb: 2,
          }}
        >
          {t("welcome.login_button_text")}
        </Button>
        <Box maxWidth={250} mx="auto">
          <Stack direction="column">
            <Button
              color="neutral"
              component={Link}
              fullWidth={true}
              href="https://ahapay.my/signup"
              size="medium"
              startIcon={<IconInfo />}
              sx={{
                justifyContent: "flex-start",
              }}
              // @ts-ignore TODO: fix props when provide component prop in ui-web
              target="_blank"
              variant="link"
            >
              {t("welcome.merchant_service_link_text")}
            </Button>
            <Button
              color="neutral"
              component={Link}
              fullWidth={true}
              href="https://ahapay.my"
              size="medium"
              startIcon={<IconOpenIn />}
              sx={{
                justifyContent: "flex-start",
              }}
              // @ts-ignore TODO: fix props when provide component prop in ui-web
              target="_blank"
              variant="link"
            >
              {t("welcome.client_app_link_text")}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};
