import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { t } from "i18next";
import { getCode } from "shared/api";

export const getCodeErrorMessage = (error: unknown) => {
  if (!(error instanceof AxiosError)) {
    return;
  }

  const errorCode = error.response?.data?.error?.code;

  if (errorCode === "countdown_error") {
    return t("login.countdown_error");
  }

  return t("login.phone_length_error");
};

export const useGetCode = () => {
  return useMutation({
    mutationFn: getCode,
  });
};
