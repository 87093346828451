import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  PageTitle,
  RadioCard,
  RadioGroup,
  Stack,
  Typography,
} from "shared/ui";

import { outletsPageLoader } from "./loader";

export const PageOutlets = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { defaultTradeOutletId, outlets } = useLoaderData() as Awaited<
    ReturnType<typeof outletsPageLoader>
  >;

  const [selectedOutlet, setSelectedOutlet] = useState(defaultTradeOutletId);

  const handleChangeButtonClick = () => {
    if (selectedOutlet) {
      localStorage.setItem("currentTradeOutletId", selectedOutlet);
    }

    navigate(-1);
  };

  const handleCancelButtonClick = () => {
    navigate(-1);
  };

  return (
    <Container
      sx={{
        py: 2,
      }}
    >
      <PageTitle
        subtitle={t("outlets.description")}
        title={t("outlets.title")}
      />
      <Typography mb={2} variant="bigTextMedium">
        {t("outlets.outlet_radio.label")}
      </Typography>
      <RadioGroup
        onChange={(e) => {
          setSelectedOutlet(e.currentTarget.value);
        }}
        value={selectedOutlet}
      >
        <Stack spacing={1}>
          {outlets.map((outlet) => {
            return (
              // OutletRadioCard
              <RadioCard key={outlet.id} value={outlet.id}>
                <Typography variant="regularTextRegular">
                  {outlet.name}
                </Typography>
                <Typography variant="smallTextRegular" color="neutral.50">
                  {outlet.location}
                </Typography>
              </RadioCard>
            );
          })}
        </Stack>
      </RadioGroup>
      <Box
        mt={{
          xs: "112px",
          md: 2,
        }}
      >
        <Box
          bottom={16}
          left={16}
          position={{
            xs: "fixed",
            md: "static",
          }}
          right={16}
        >
          <Button
            fullWidth={true}
            onClick={handleChangeButtonClick}
            sx={{
              mb: 1,
            }}
          >
            {t("outlets.submit_button_text")}
          </Button>
          <Button
            fullWidth={true}
            onClick={handleCancelButtonClick}
            variant="secondary"
          >
            {t("common.cancel")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
