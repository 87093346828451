import { Outlet, useNavigation } from "react-router-dom";
import { LoadingOverlay } from "shared/ui";

export const PageRoot = () => {
  const navigation = useNavigation();
  return (
    <>
      <LoadingOverlay open={navigation.state === "loading"} />
      <Outlet />
    </>
  );
};
