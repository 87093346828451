import { bnplMerchants } from "@mychili/frontend-libs-api-core";

export type RefundOrderParams = {
  orderId: string;
};

export const refundOrder = async ({
  orderId,
}: RefundOrderParams): Promise<boolean> => {
  const response =
    await bnplMerchants.postApiMerchantsServiceEmployeeOrdersIdRefund(orderId);

  if (!response.data.data?.success) {
    throw new Error("refundOrder -> no success response");
  }

  return response.data.data?.success;
};
