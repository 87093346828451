import { Theme } from "@mychili/ui-web";

export type CountryConfig = {
  code: "MY";
  currencies: {
    code: string;
    maxFractionDigits: number;
  };
  locales: {
    default: string;
  };
  phones: {
    prefix: {
      international: string;
      national: string;
    };
  };
};

export type ProjectConfig = {
  contacts: {
    supportEmail: string;
  };
};

export type Config = {
  country: CountryConfig;
  project: ProjectConfig;
  theme: Theme;
};

export let config: Config;

export const getConfig = () => {
  return config;
};

export const init = ({ country, project, theme }: Config) => {
  config = {
    country,
    project,
    theme,
  };
};

export const Project = {
  init,
};
