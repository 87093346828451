import { getConfig } from "shared/lib/config";

export const parsePhoneNumber = (s: string) => {
  const config = getConfig();

  const prefixInternational = config.country.phones.prefix.international;
  const prefixNational = config.country.phones.prefix.national;

  // Будет работать, пока в маске нет других разделителей, кроме пробелов
  const raw = s.replace(/\s/g, "");

  if (raw.startsWith(prefixInternational)) {
    return raw;
  }

  const startsWithPrefixNationalRegex = new RegExp(`^${prefixNational}`);

  if (raw.startsWith(prefixNational)) {
    return raw.replace(startsWithPrefixNationalRegex, prefixInternational);
  }

  return "";
};
