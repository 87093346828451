import { CssBaseline, ThemeProvider as Provider } from "@mychili/ui-web";
import { getConfig } from "shared/lib/config";

export type ThemeProviderProps = { children: React.ReactNode };

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const config = getConfig();

  return (
    <Provider theme={config.theme}>
      <CssBaseline />
      {children}
    </Provider>
  );
};
