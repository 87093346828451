import "shared/lib/i18n";

import { getCountryConfig, getProjectConfig, getTheme } from "app/configs";
import React from "react";
import ReactDOM from "react-dom/client";
import { Axios } from "shared/lib/axios/axios";
import { Project } from "shared/lib/config";

import { App } from "./app";

Axios.init();

Project.init({
  country: getCountryConfig(window.injectedEnv.REACT_APP_COUNTRY_CODE),
  project: getProjectConfig(window.injectedEnv.REACT_APP_PROJECT_CODE),
  theme: getTheme(window.injectedEnv.REACT_APP_THEME_KEY),
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
