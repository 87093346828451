import OTPInput, { OTPInputProps } from "react-otp-input";

import { Input, InputProps } from "../input";
import { Stack } from "../stack";
import { Typography } from "../typography";

export type InputCodeProps = Omit<InputProps, "onChange"> &
  Omit<OTPInputProps, "renderInput">;

// TODO: to ui kit
export const InputCode = ({
  disabled,
  error,
  helperText,
  numInputs,
  onChange,
  value,
  ...props
}: InputCodeProps) => {
  const handleChange = (newValue: string) => {
    onChange(newValue.replace(/\D/g, ""));
  };

  const renderInput = (inputProps: any) => {
    return (
      <Input
        disabled={disabled}
        error={error}
        inputProps={{
          ...inputProps,
          style: { textAlign: "center" },
          "data-test": props?.inputProps?.["data-test"],
        }}
        InputProps={{
          sx: {
            height: 48,
            width: 48,
          },
        }}
        sx={{
          width: "auto",
          "& + .MuiTextField-root": {
            ml: 1.5,
          },
        }}
        color="white"
      />
    );
  };

  return (
    <Stack
      mb={{
        xs: 2,
        md: 3,
      }}
      justifyContent="center"
    >
      <OTPInput
        value={value}
        onChange={handleChange}
        numInputs={numInputs}
        renderSeparator={false}
        shouldAutoFocus={true}
        inputType="tel"
        containerStyle={{
          justifyContent: "center",
        }}
        renderInput={renderInput}
        {...props}
      />
      {helperText ? (
        <Typography
          color={error ? "error" : undefined}
          sx={{
            mt: 2,
            textAlign: "center",
          }}
          variant="regularTextRegular"
        >
          {helperText}
        </Typography>
      ) : null}
    </Stack>
  );
};
