import { useSendOrderLink } from "features/send-order-link";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "shared/lib/common";
import { getConfig } from "shared/lib/config";
import { parsePhoneNumber } from "shared/lib/phones";
import {
  Box,
  Button,
  IconCall,
  IconMail,
  Input,
  InputPhone,
  Show,
  Stack,
  Typography,
} from "shared/ui";

export type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  orderId: string;
};

export type FieldValues = {
  email?: string;
  phone?: string;
};

export const Form = ({ onCancel, onSuccess, orderId }: Props) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const sendOrderLink = useSendOrderLink();

  const {
    formState,
    handleSubmit: createSubmitHandler,
    register,
    reset,
    setError,
  } = useForm<FieldValues>({
    defaultValues: {
      email: "",
      phone: "",
    },
  });

  const config = getConfig();

  const handleCancelButtonClick = () => {
    reset();
    onCancel();
  };

  const handleSubmit: SubmitHandler<FieldValues> = (data) => {
    if (data.email && data.phone) {
      return setError("root.multiple", {
        message: t("orders.send_qr_modal.single_input_error"),
      });
    }

    sendOrderLink.mutate(
      {
        email: data.email || undefined,
        orderId,
        phone: data.phone ? parsePhoneNumber(data.phone) : undefined,
      },
      {
        onError: (error) => {
          // @ts-ignore
          const emailError = error?.response?.data?.error?.errors?.email?.[0];
          // @ts-ignore
          const phoneError = error?.response?.data?.error?.errors?.phone?.[0];

          if (emailError) {
            setError("email", {
              message: t("orders.send_qr_modal.email_input.error"),
            });
          }

          if (phoneError) {
            setError("phone", {
              message: t("orders.send_qr_modal.phone_input.error"),
            });
          }
        },
        onSuccess: () => {
          enqueueSnackbar({
            variant: "success",
            message: t("orders.send_qr_modal.notification.success"),
          });
          reset();
          onSuccess();
        },
      },
    );
  };

  const isDirty = formState.isDirty;
  return (
    <Box component="form" onSubmit={createSubmitHandler(handleSubmit)}>
      <Typography color="neutral.20" mb={1} variant="bigTextMedium">
        {t("orders.send_qr_modal.title")}
      </Typography>
      <Typography color="neutral.60" mb={3} variant="regularTextRegular">
        {t("orders.send_qr_modal.description")}
      </Typography>

      <Box mb={2}>
        <InputPhone
          autoFocus={true}
          color="gray"
          countryCode={config.country.code}
          error={Boolean(formState.errors.phone)}
          helperText={formState.errors.phone?.message}
          InputProps={{
            startAdornment: <IconCall />,
          }}
          inputProps={{
            type: "tel",
          }}
          placeholder={t("orders.send_qr_modal.phone_input.placeholder")}
          {...register("phone")}
        />
        <Typography
          align="center"
          color="neutral.50"
          my={1}
          variant="regularTextRegular"
        >
          {t("orders.send_qr_modal.inputs_delimiter")}
        </Typography>
        <Input
          color="gray"
          error={Boolean(formState.errors.email)}
          InputProps={{
            startAdornment: <IconMail />,
          }}
          helperText={formState.errors.email?.message}
          placeholder={t("orders.send_qr_modal.email_input.placeholder")}
          {...register("email")}
        />
        <Show when={formState.errors.root?.multiple?.message}>
          {(value) => (
            <Typography color="error" mt={1} variant="regularTextRegular">
              {value}
            </Typography>
          )}
        </Show>
      </Box>

      <Stack spacing={1}>
        <Button
          disabled={!isDirty}
          fullWidth={true}
          loading={sendOrderLink.isPending}
          type="submit"
        >
          {t("common.send")}
        </Button>
        <Button
          disabled={sendOrderLink.isPending}
          fullWidth={true}
          onClick={handleCancelButtonClick}
          variant="secondary"
        >
          {t("common.cancel")}
        </Button>
      </Stack>
    </Box>
  );
};
