import { getConfig } from "shared/lib/config";

export const format = (value: number): string => {
  const config = getConfig();

  const locale = config.country.locales.default;
  const currency = config.country.currencies.code;
  const currencyMaxFractionDigits = config.country.currencies.maxFractionDigits;

  const formatter = new Intl.NumberFormat(locale, {
    currency,
    currencyDisplay: "narrowSymbol",
    maximumFractionDigits: currencyMaxFractionDigits,
    minimumFractionDigits: 0,
    style: "currency",
  });

  return formatter.format(value);
};
