import { useLogout } from "features/logout";
import { useNavigate, useRouteError } from "react-router-dom";
import { Paths } from "shared/config";
import { getConfig } from "shared/lib/config";
import { getErrorMessage } from "shared/lib/router";
import { Box, Button, Container, Link, Typography } from "shared/ui";

export const PageProtectedErrorElement = () => {
  const navigate = useNavigate();
  const error = useRouteError();
  const errorMessage = getErrorMessage(error);
  const logout = useLogout();

  const config = getConfig();

  const handleLogoutButtonClick = () => {
    logout.mutate(undefined, {
      onSettled: () => {
        navigate(Paths.Login);
      },
    });
  };

  if (errorMessage === "No outlets") {
    return (
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          p: 2,
        }}
      >
        <Typography align="center" mb={1} variant="bigTextSemiBold">
          No binded outlets
        </Typography>
        <Typography mb={1} align="center" color="common.outline">
          You have not been allocated a specific trade outlet. Please contact{" "}
          <Link
            href={`mailto:${config.project.contacts.supportEmail}`}
            underline="none"
          >
            our support team
          </Link>
          .
        </Typography>
        <Box
          mt={{
            xs: "76px",
            md: 2,
          }}
        >
          <Box
            bottom={16}
            left={16}
            position={{
              xs: "fixed",
              md: "static",
            }}
            right={16}
          >
            <Button fullWidth={true} onClick={handleLogoutButtonClick}>
              Logout
            </Button>
          </Box>
        </Box>
      </Container>
    );
  }

  throw error;
};
